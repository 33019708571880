.discussion-card {
  overflow: auto;
}

.feedback-post {
  padding-left: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  border-left: 1px solid $color-dark;
  width: 600px;

  .date-time {
    color: $color-gray-light;
    display: inline-block;
  }

  // @todo Linda: Feedback-Header und -Text noch stylen
  &__header {
    &--initial-post {
      .user-name-bubble {
        &::before {
          width: 0px;
        }
      }
    }
  }

  &__text {}
}