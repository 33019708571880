// only to be included in all styles-classes of HTML elements that are not <Card>,
// since only <Card> is automatically decorated with .card by reactstrap
@mixin card {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex-grow: 1; // @todo: collapse card wird angeblich durch diese Einstellung vergrößert bei schmaler Fensterbreite -> prüfen: wo?
  box-shadow: $box-shadow-default;
  border-radius: 0.625rem;
  border: none;
  padding: 0 1.5rem;
  margin-bottom: 0.5rem;

  @media screen and (min-width: $md) {
    margin-bottom: 1rem;
  }
}

// is automatically added by reactstrap, when <Card> is used in code
.card {
  @include card;

  // @todo: nach Konvention eigentlich &__header, diese Klasse wird aber von Bootstrap gezogen, Ziel: Davon unabhängig machen 
  &-header {
    display: flex;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 1.75rem 0;
  }

  // @todo prüfen, ob diese Klasse umbenannt werden kann, da sie vermutlich ein Element von React/Bootstrap ist
  // @todo: würde einen Umbau vorschlagen, da jetzt das H3-Tag alle Inhalte umschließt, das führt beim Styling zu Problemen (z.B. kann ich den project-implementation-state mit -1rem ranrücken 
  .title-section {
    display: flex;
    flex-direction: column;

    &--with-image {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin: 0em 2.5em 0em 0em !important;
        width: 20%;
        height: 20%;
        max-width: 300px;
      }

      .title-section-text {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }
    }

    &--short {
      h3 {
        display: inline;
        margin: 0;
      }
    }

    // place an element next to the title-section
    &--with-element {
      display: flex;
      flex-direction: row;
      gap: 1.75rem;
      justify-content: flex-start;
      background: none;
      border: none;

      svg {
        width: 1.563rem;
        height: 1.563rem;
      }

      // center the check icon
      :first-child {
        margin-top: auto;
        margin-bottom: auto;
      }

      .title-section h3 {
        margin-bottom: 0.4rem;
      }

      .title-section-text {
        align-items: flex-start;
        justify-content: center;
      }

      .title-section-text h3 {
        margin: 0rem;
      }

      .actionicon {
        margin: 0rem;
        padding: 0rem;

        svg {
          opacity: 0.325;
        }
      }
    }

    &--with-icon-navigation {
      display: flex;
      justify-content: space-between;
      flex-grow: 2;
      flex-wrap: wrap;
      align-items: center;
    }

    // @todo Linda: prüfen, ob man diese Klasse in eine allgemeine .text-semi-muted zusammenfasst, da dieser Klassenname sehr use-case spezifisch ist, aber zwei eher generelle Styles beinhaltet, die auch anderswo Einsatz finden könnten
    .project-information {
      color: $color-gray;
      font-size: $font-size-sm;
    }

    .text-success {
      span {
        color: inherit;
      }
    }

    .project-implementation-state {
      display: inline-block;

      &__icon {
        margin-right: 0.5rem;
      }
    }
  }

  // @todo: nach Konvention eigentlich &__title, diese Klasse wird aber von Bootstrap gezogen, Ziel: Davon unabhängig machen 
  // @todo Abgleich mit H3, benutzt man lieber .card__title oder H3, aktuell 5 Vorkommen, mal H2, mal H3
  &-title {
    font-size: $h3-font-size;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  // @todo: nach Konvention eigentlich &__body, diese Klasse wird aber von Bootstrap gezogen, Ziel: Davon unabhängig machen 
  &-body {
    padding: 1.75rem 0;

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child) {
      margin-top: 1em;
    }
  }
}

// is neither a sub-element nor a modifier of .card, but an own react-component
.collapsable-card {
  flex-grow: 0;
  margin-left: 0;
  margin-right: 0;
  min-height: 0;
  width: 100% !important;
  max-width: 1800px !important;
  min-width: 0 !important;

  .card-header {
    background: none;
    border: none;
    align-items: center;
    cursor: pointer;
  }

  .collapse .show {
    display: flex;
    flex-direction: column;
  }

  >.collapse .card-body,
  >.collapsing .card-body {
    padding: .5rem 0 1rem;
    margin-top: 0;

    >.icon-navigation {
      margin: 0 0
    }
  }
}

// positioning cards to each other
.card-grid-layout {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  padding: 1rem;

  @media screen and (min-width: $sm) {
    grid-template-columns: 50% 50%;
  }

  @media screen and (min-width: $lg) {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }

  @media screen and (min-width: 1700px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
}