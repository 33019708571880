/** 
  * empty file, required for the import from index.scss,
  * overwritten by customized builds for customer specific individualizations
  * @see example file at /examples/_custom_styles_example.scss 
  */


// start custom frontpage
.frontpage {

  //different logo on frontpage than on plattform + mobile
  header>.row .header-logo {
    background-image: url("/assets/img/logo-frontpage.svg");

    @media screen and (max-width: 760px) {
      background-image: url("/assets/img/logo-frontpage-mobile.svg");
    }
  }

  //custom background image bottom right
  &:after {
    background-image: url("/assets/img/city_rotate.svg");
  }

  .footer-links a:not(col .social-icons) {
    border-bottom: 2px solid #000000 !important;
  }

  .btn-frontpage {
    color: $color-white;
    border: $color-white 2px solid;
    background-color: $color-dark;
  }
}

// end custom frontpage