// FormikFieldArrays, e.g. goals, partners and more
// @todo Linda: kürzen wo geht 

.flexbox-icon-navigation-inputfields {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;

  @media screen and (min-width: $xs) {
    margin-bottom: 1rem;
  }

  .text-danger {
    margin-bottom: 1rem;
  }

  .input-array-box {
    width: 100%;
    margin-right: 1rem;
  }

  .icon-navigation {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    align-self: flex-end;
  }

  .inputfield {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .invalid-feedback {
    width: 100%;
    margin-top: 3em;
    font-size: 80%;
    color: #dc3545;
    position: absolute;
  }

  // made sure, that the inputfield is over the full width of the form, for a FormikFieldArray with one or several inputfields
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.1rem !important;

    label {
      display: none;
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      margin-bottom: 0.5rem;
    }
  }
}

// specific adaption for the feedback array => the array will be show over the whole display and the error messages are visible below the specific field
.input-question-array {
  width: 100%;

  .invalid-feedback {
    margin-top: 0em;
    position: relative;
  }
}

.multi-input-array {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding: 0rem;
  flex-wrap: wrap;

  @media screen and (min-width: $xs) {
    flex-wrap: none;
  }

  .inputfield {
    width: 100%;
  }

  // Both inputFields should fill the whole available space
  >div {
    flex-grow: 1;
  }

  //placing the goal icon ahead of the goal
  div:first-child :nth-child(1) {
    small {
      padding-left: 2.25rem;
    }

    .inputfield::before {
      content: url(/assets/icons/goal.svg);
      margin-top: 0.3rem;
      margin-right: 0.75rem;
      transform: scale(1.1);
    }
  }

  //belongs to placing the arrow between the two inputfields
  div:first-of-type:not(.inputfield):not(.input-group) {
    margin-right: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  // the inputfield after the arrow will get more space till the trash icon
  div:last-child .inputfield .form-group,
  .flexbox-in-a-row :first-child {
    margin-right: 0rem;
  }

  //belongs to placing the arrow between the two inputfields
  :not(.inputfield):not(small):not(.form-group):not(.was-validated):nth-child(2)::before {
    content: url(/assets/icons/arrow-right.svg);
    align-self: flex-end;
    margin-bottom: 0.75rem;
    margin-left: 0.6rem;
    margin-right: 1.75rem;

    @media screen and (max-width: $sm) {
      margin-right: 0.75rem;
      margin-left: 0rem;
    }
  }

  //the right arrow icon will be displayed in one line (row) with the inputfield and their help element
  // todo Linda: zu generellem Style machen?
  .flexbox-in-a-row {
    display: flex;
    flex-direction: row;
    // the inputfields will take all available space

    div {
      width: 100%;
    }
  }

  // display mode in index
  &--view {
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    div {
      word-wrap: break-word;
      min-width: 0%;
    }
  }
}