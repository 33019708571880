.timetable-card {
  padding: 0 2.25rem;

  .card-body {
    overflow-x: auto;
  }
}

.timetable {
  min-width: 1000px;
  width: 99%;

  thead th {
    border: none;
  }

  td {
    border-right: 1px solid $color-dark;
    border-left: 1px solid $color-dark;
    border-top: 1px solid adjust_color($color-dark, $alpha: -0.9);
  }

  th:first-child,
  td:first-child {
    border: none;
    width: 18rem;
    font-size: $font-size-sm;
  }

  .first-of-year {
    border-left: 2px solid $color-dark;
  }

  tr {
    td:nth-child(2) {
      border-left: 1px solid $color-dark;
    }
  }

  tr.years {
    td {
      border: none;
      padding: 0;
      font-weight: bold;
    }
  }

  tr.months {
    td {
      padding: 0;

      div {
        display: flex;
      }

      span {
        display: flex;
        justify-content: center;
        padding: 0.25rem;
        background: $color-gray-lighter;
        width: 100%;
      }
    }
  }

  &__timespan {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  &__task-row {

    td:not(:first-child) {
      &:hover {
        background: lighten($color-primary, 35%);
        cursor: pointer;
      }
    }

    td.active {
      background: lighten($color-primary, 25%);
    }

    &__cell {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__wp-row {
    td {
      padding: 0 2rem 0 0;
      border-left: none;
      border-right: none;

      &:last-child {
        border-right: 1px solid $color-dark;
      }
    }

    td.active {
      background: lighten($green, 45%);
    }

    .work-package-card {
      width: auto;
      font-size: 1.125rem;
      padding: 0.5rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .no-tasks {
      cursor: auto;
    }

    .chevron-right {
      display: flex;
      align-items: center;
      transform: rotate(90deg)
    }
  }

  &__option-icons {
    display: flex;
    justify-content: flex-end;
  }
}