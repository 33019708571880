// uses mixin @include btn;

.icon-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0.625rem 0;
  gap: 0.7rem;

  .btn {
    &:hover {
      background-color: transparent;
      border: none;
    }

    &:focus-visible {
      @include focus-visible--no-box-shadow;
    }
  }
}

/* #region page-navigation */

.page-navigation-area {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  height: 2rem;
}

// @todo: Sind die Mixin-Imports nötig, wenn jeder <Button> die btn Klasse bekommt?  
.page-navigation-btn {
  @include btn;

  background-color: $color-gray-lighter;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  border: solid 1px $color-white;
  min-height: 36px;
  gap: 0.5rem;

  &:hover {
    background-color: $color-gray-light;
  }

  &__text {
    display: none;

    @media screen and (min-width: $md) {
      display: block;
    }
  }

  &--left {
    position: absolute;
    left: 0;
  }

  &--center,
  &--right {
    position: absolute;
  }

  &--right {
    right: 0;
  }
}

/* #endregion page-navigation */