@charset "UTF-8";

/*
Styles, die von Coder benötigt werden/angelegt werden, aber noch nicht von Linda eingearbeitet wurden
*/

/* #region norbert */

// ImageUploadField hängt drag-active an das Input-Field und den (nach Button ausschauenden) Bereich ran.
// ich kriege das Styling aber dennoch nicht hin (NR)
.drag-active {}

// used to show info texts, e.g. HiddenObMobile
.text-info {
  max-width: 400px;
  align-self: center;

  text-align: center;
  border: 1px solid $color-blue;
  border-radius: 12px;
  padding: 1rem;
}

/* #endregion norbert */

/* #region frieder */

// wird in der FollowershipCard gebraucht: die Karte fungiert als Link, next/link braucht ein Ziel für das ref, weshalb ein <a>-tag eingeführt wird.
// Dieses produziert aber einen Link-typischen Unterstrich unter die Karte (zu sehen in der "Meine Befürwortungen"-Übersicht), der hierdurch neutralisiert wird.
.no-link-underline {
  border-bottom: none !important;
}

/* #endregion frieder */

/* #region linda */
// Styles werden nach Renaming Branch eingearbeitet, sonst kommt es zu vielen Konflikten

.special-function-icon-btn {
  padding: 0.8rem;
  background-color: $color-white;
  box-shadow: inset 0 0 0 1px $color-dark;

  &:hover {
    background-color: $color-gray-lighter;
  }

  &--active {
    background-color: $color-primary;
    background-image: $background-image;
    color: $color-on-primary;
    box-shadow: none;
    border: none;

    &:hover {
      box-shadow: none;
      border: none;
      background-image: radial-gradient(circle at top left, $color-gray-lighter, $color-gray-lighter)
    }
  }

  &--count {
    border-radius: 99px;
    background-color: $color-white;
    border: 1px solid $color-dark;
    position: relative;
    height: fit-content;
    padding: 0.1rem 0.4rem;
    font-size: 0.813rem;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-left: 35px;
    margin-top: 43px;
    text-align: center;
    position: absolute;

    &--active {
      background-color: $color-primary;
      background-image: $background-image;
      color: $color-on-primary;
      box-shadow: none;
      border: none;
    }
  }
}

.followership-card-layout {
  display: flex;
  gap: 0.2rem 1rem;
  flex-wrap: wrap;
}

.followership-card {
  padding: 0px;

  &__body {
    padding: 0.5rem !important;
  }

  .special-function-icon-btn {
    margin-left: 0.5rem;
    scale: 0.8;
  }
}

/* #region language-selector */
.language-selector {
  background-color: $color-white;
  border: solid 1px $color-dark;
  border-radius: 9px;
  padding: 0.4rem 0.6rem;
  max-width: fit-content;

  &:hover {
    color: $color-dark;
    background-color: $color-gray-lighter;
  }
}

.language-selector__dropdown {
  text-align: right;

  @media screen and (max-width: $sm) {
    text-align: center;
  }

  .dropdown-menu {
    background-color: #ffffff;
    background-image: none;
    box-shadow: none;
    min-width: 0;
    padding: 0.4rem 0.6rem;
    right: 13px;
    bottom: 45px;
    border: solid 1px $color-dark;
    border-radius: 9px;
    // inline styles, that need to be overwritten
    top: unset !important;
    left: unset !important;
    transform: none !important;

    @media screen and (max-width: $sm) {
      right: 0;
      bottom: 38px;
      width: fit-content;
      margin: 0 auto;
      left: 0 !important;
    }
  }

  .dropdown-menu::before {
    content: none;
  }

  .dropdown-menu::after {
    position: absolute;
    content: "";
    background-image: url('/assets/img/language-selector__triangle.svg');
    bottom: -9px;
    right: 6px;
    width: 20px;
    height: 10px;
    border: none;

    @media screen and (max-width: $sm) {
      right: unset;
      margin-left: -10px;
    }

  }

  .dropdown-menu .dropdown-item {
    padding: 0.4rem;

    * {
      padding: 0;
    }

    &:hover {
      background-color: $color-gray-lighter;
    }
  }
}


/* #endregion language-selector */


/* #endregion linda */

/* #region phillipp */

// @todo Linda: Prüfen in wie weit es diese Styles schon gibt, Umsetzung in Ticket <FCP-1041>
/* #region button: placing icons in the center */
/* button,
  .btn,
  .btn-secondary {

    &--icon {
      padding: 1rem;

      svg {
        margin-right: 0;
      }
    }
  }
  */
/* #endregion button  */

// Folgende Situation: 
// Für Bewerbungen soll es eine Karte geben, wo es die möglichkeit gibt sich die PDF anzuzeigen, zu generieren und die Bewerbung zu löschen.
// Wenn die Bewerbung inanktiv ist soll dem Benutzer ein Button zum aktivieren der Bewerbung angezeigt werden.
// Darstellung: Der Aktivierungs ist links und die Icons (PDF, Lösch-Icon) sind rechts.
// Unter 1064px werden die Buttons und die Icons untereinander dargestellt, wenn ein PDF vorhanden ist und entsprechender Text angzeigt wird, spätestens dann sollte Buttons und Icons in der Breite zentriert werden.
// justify-content mit dem Breakpoint ist der einzige Unterschied zur CSS-Klasse icon-navigation
// Verwendung aktuell in components/project/proposals/ProposalDetailsCard
// allgemeiner Gedanke: Navigation icons sind rechts und die Button auf den Pages für Bewerbungen meistens link
.icon-navigation {
  &--with-confirmation-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0.625rem 0;
    grid-gap: 0.7rem;
    gap: 0.7rem;

    @media screen and (min-width: $xl) {
      justify-content: space-between;
    }

  }
}

// Folgende Siuation: Ausschreibung auswählen: Karte bereits ausgewählte Ausschreibungen: aktive Bewerbung: das aktiv-icon klebt am rechten Text (Challenge Name)...
// Lösung: Abstand zu rechts einbauen
.card {
  .title-section {
    .text-success {
      margin-right: 1rem;
    }
  }
}

/* #endregion phillipp */