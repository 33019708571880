a:not(.btn, .workflow__box, .image-filteritem, .actionicon, .header-logo),
.looks-like-a-link {
  transition: all 0.175s ease-in-out;
  border-bottom: 2px solid;
  border-image: $background-image;
  border-image-slice: 1;

  &:hover {
    border-bottom-width: 4px;
  }

}

a:not(.btn, .header-logo),
a:not(.btn, .header-logo):hover,
a:not(.btn, .header-logo):visited {
  color: inherit;
  text-decoration: none;
  position: relative;
  display: inline;
}