/* #region common projects card */
.project-card {

  .card-header {
    align-items: flex-start;
  }

  .card-body {
    p {
      margin: 0;
    }
  }

  .dropdown {
    display: flex;
  }

  .card-footer {
    border: none;
    background: transparent;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1rem;
  }

  &--empty {
    border: 3px dashed #cccccc;
    box-shadow: none;

    .card-body {
      display: flex;
      flex-grow: 1;
      margin-bottom: 2rem;
      padding: 0;
      font-size: 1.125rem;
      font-family: $font-heading;
      font-style: italic;
      font-weight: 400;
    }
  }
}

/* #endregion common projects card */

/* #region team-upload */
// @todo: Styles komplett überarbeiten, wahrscheinlich sogar mit Entwurf

// for file uploads use the label as button as well as drag & drop area by spanning the transparent input to fill the container completely
// @todo: Instanzen prüfen, eigentlich in media.scss
.fileupload {

  &__container {
    position: relative;
  }

  // @todo FCP-1064 + klären, ob __hidden dann noch ein Modifier ist oder ein eigenes Element
  &__hidden {
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
  }
}

// @todo: Instanzen checken, wenn nur in Team-Upload: umbenennen
.fileupload-table {
  width: 100%;

  &__title-col {
    position: relative;
    vertical-align: top;
    left: 2.5rem;
  }

  &__category-col {
    position: relative;
    vertical-align: top;
    left: 1.5rem;
  }

  &__file-col {
    position: relative;
    vertical-align: top;
    left: 4rem;
  }
}

/* #endregion team-upload */

/* #region team-video */
.teamvideo {
  display: flex;
  justify-content: center;
}

/* #endregion team-video */